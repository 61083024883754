import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const size = '35px';
const pos = 30;
const spinRotate = keyframes({
  '0%': {
    transform: 'rotate(0deg)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  },
  '50%': {
    transform: 'rotate(180deg)'
  }
});
export default (color => ({
  height: size,
  overflow: 'hidden',
  position: 'relative',
  width: size,
  ' > div': _objectSpread({}, globalAnimation(), {
    animation: `${spinRotate} 0.6s 0s infinite linear`,
    backgroundImage: `linear-gradient(transparent 0%, transparent ${100 - pos}%, ${color || PRIMARY_COLOR} ${pos}%, ${color || PRIMARY_COLOR} 100%)`,
    borderRadius: '100%',
    borderWidth: '0px',
    height: '100%',
    position: 'absolute',
    width: '100%'
  })
}));