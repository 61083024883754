import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(0.6)'
  }
});
export default (color => ({
  position: 'relative',
  ' > div': _objectSpread({}, globalAnimation(), {
    animation: `${rotate} 1s 0s ease-in-out infinite`,
    border: `2px solid ${color || PRIMARY_COLOR}`,
    borderBottomColor: 'transparent',
    borderRadius: '100%',
    borderTopColor: 'transparent',
    height: '35px',
    left: '-20px',
    position: 'absolute',
    top: '-20px',
    width: '35px',
    '&:last-child': {
      animationDirection: 'reverse',
      animationDuration: '0.5s',
      borderColor: `${color || PRIMARY_COLOR} transparent ${color || PRIMARY_COLOR} transparent`,
      display: 'inline-block',
      height: '15px',
      left: '-10px',
      top: '-10px',
      width: '15px'
    }
  })
}));