import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation, lines } from '../mixins';
import delay from '../utilities';
const lineScalePulseOut = keyframes({
  '0%': {
    transform: 'scaley(1.0)'
  },
  '100%': {
    transform: 'scaley(1.0)'
  },
  '50%': {
    transform: 'scaley(0.4)'
  }
});
export default (color => ({
  ' > div': _objectSpread({}, lines(color), {}, globalAnimation(), {
    animation: `${lineScalePulseOut} 0.9s ${delay('0.2s', 3, 0)} infinite cubic-bezier(.85,.25,.37,.85)`,
    display: 'inline-block',
    '&:nth-of-type(2), &:nth-of-type(4)': {
      animationDelay: `${delay('0.2s', 3, 1)} !important`
    },
    '&:nth-of-type(1), &:nth-of-type(5)': {
      animationDelay: `${delay('0.2s', 3, 2)} !important`
    }
  })
}));