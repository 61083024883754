import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
import delay from '../utilities';
const amount = '10px';
const ballPulseSync = keyframes({
  '100%': {
    transform: 'translateY(0)'
  },
  '33%': {
    transform: `translateY(${amount})`
  },
  '66%': {
    transform: `translateY(-${amount})`
  }
});
export function ballPulse() {
  const n = 3;
  let i = 1;
  const grid = {};

  for (; i < 4; i += 1) {
    grid[`> div:nth-of-type(${i})`] = {
      animation: `${ballPulseSync} 0.6s ${delay('0.07s', n, i)} infinite ease-in-out`
    };
  }

  return grid;
}
export default (color => _objectSpread({}, ballPulse(), {
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    display: 'inline-block'
  })
}));