import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const ballBeat = keyframes({
  '100%': {
    opacity: 1,
    transform: 'scale(1)'
  },
  '50%': {
    opacity: 0.2,
    transform: 'scale(0.75)'
  }
});
export default (color => ({
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    '&:nth-of-type(2n-1)': {
      animationDelay: '-0.35s'
    },
    animation: `${ballBeat} 0.7s 0s infinite linear`,
    backgroundColor: color || PRIMARY_COLOR,
    display: 'inline-block'
  })
}));