import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
const ballPulseRound = keyframes({
  '0%': {
    transform: 'scale(0.0)'
  },
  '100%': {
    transform: 'scale(0.0)'
  },
  '40%': {
    transform: 'scale(1.0)'
  },
  '80%': {
    transform: 'scale(0.0)'
  }
});
export default (color => ({
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    animation: `${ballPulseRound} 1.2s infinite ease-in-out`,
    height: '10px',
    width: '10px'
  })
}));