import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation, lines } from '../mixins';
import delay from '../utilities';
const ballBeat = keyframes({
  '0%': {
    transform: 'scaley(1.0)'
  },
  '100%': {
    transform: 'scaley(1.0)'
  },
  '50%': {
    transform: 'scaley(0.4)'
  }
});

function lineScale() {
  const n = 5;
  let i = 1;
  const grid = {};

  for (; i < 10; i += 1) {
    grid[`> div:nth-of-type(${i})`] = {
      animation: `${ballBeat} 1s ${delay('0.1s', n, i)} infinite cubic-bezier(.2,.68,.18,1.08)`
    };
  }

  return grid;
}

export default (color => _objectSpread({}, lineScale(), {
  ' > div': _objectSpread({}, lines(color), {}, globalAnimation(), {
    display: 'inline-block'
  })
}));