import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
import delay from '../utilities';
const size = '60px';
const ballScaleMultiple = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.0)'
  },
  '100%': {
    opacity: 0,
    transform: 'scale(1.0)'
  },
  '5%': {
    opacity: 1
  }
});
export function ballScale() {
  const n = 3;
  let i = 2;
  const grid = {};

  for (; i < 4; i += 1) {
    grid[`> div:nth-of-type(${i})`] = {
      animationDelay: delay('0.2s', n, i)
    };
  }

  return grid;
}
export default (color => _objectSpread({}, ballScale(), {
  position: 'relative',
  transform: `translateY(-${size} / 2)`,
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    animation: `${ballScaleMultiple} 1s 0s linear infinite`,
    height: size,
    left: '-30px',
    margin: '0',
    opacity: 0,
    position: 'absolute',
    top: '0px',
    width: size
  })
}));