import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(0.6)'
  }
});
const scale = keyframes({
  '100%': {
    transform: 'scale(1)'
  },
  '30%': {
    transform: 'scale(0.3)'
  }
});
export default (color => ({
  position: 'relative',
  transform: 'translateY(-15px)',
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    borderRadius: '100%',
    left: '0px',
    position: 'absolute',
    top: '0px',
    '&:first-of-type': {
      animation: `${scale} 1s 0s cubic-bezier(.09,.57,.49,.9) infinite`,
      background: color || PRIMARY_COLOR,
      height: '16px',
      left: '-7px',
      top: '7px',
      width: '16px'
    },
    '&:last-child': {
      animation: `${rotate} 1s 0s cubic-bezier(.09,.57,.49,.9) infinite`,
      animationDuration: '1s',
      background: 'transparent',
      border: '2px solid',
      borderColor: `${color || PRIMARY_COLOR} transparent ${color || PRIMARY_COLOR} transparent`,
      height: '30px',
      left: '-16px',
      position: 'absolute',
      top: '-2px',
      width: '30px'
    }
  })
}));