import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const amount = '50px';
const size = '10px';
const ballBeat = keyframes({
  '100%': {
    transform: 'rotate(-360deg)'
  },
  '25%': {
    transform: `translateX(${amount}) scale(0.5) rotate(-90deg)`
  },
  '50%': {
    transform: `translate(${amount}, ${amount}) rotate(-180deg)`
  },
  '75%': {
    transform: `translateY(${amount}) scale(0.5) rotate(-270deg)`
  }
});
export default (color => ({
  position: 'relative',
  transform: `translate(-${parseInt(amount, 10) / 2}, -${parseInt(amount, 10) / 2})`,
  ' > div': _objectSpread({}, globalAnimation(), {
    animation: `${ballBeat} 1.6s 0s infinite ease-in-out`,
    backgroundColor: color || PRIMARY_COLOR,
    height: size,
    left: '-5px',
    position: 'absolute',
    top: '-5px',
    width: size,
    '&:last-child': {
      animationDelay: '-0.8s'
    }
  })
}));