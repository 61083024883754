import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(.6)'
  }
});
export default (color => ({
  position: 'relative',
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    position: 'relative',
    '&:first-of-type': {
      animation: `${rotate} 1s 0s cubic-bezier(.7,-.13,.22,.86) infinite`
    },
    '&:before, &:after': _objectSpread({}, balls(color), {
      content: '\'\'',
      opacity: 0.8,
      position: 'absolute'
    }),
    '&:before': {
      left: '-28px',
      top: '0px'
    },
    '&:after': {
      left: '25px',
      top: '0px'
    }
  })
}));