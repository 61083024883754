import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { BALL_SIZE, LINE_HEIGHT, LINE_WIDTH, MARGIN, PRIMARY_COLOR } from '../variables';
export const globalBg = color => ({
  backgroundColor: color || PRIMARY_COLOR
});
export const globalAnimation = () => ({
  animationFillMode: 'both'
});
export const balls = color => _objectSpread({
  borderRadius: '100%',
  height: BALL_SIZE,
  margin: MARGIN,
  width: BALL_SIZE
}, globalBg(color));
export const lines = color => _objectSpread({
  borderRadius: '2px',
  height: LINE_HEIGHT,
  margin: MARGIN,
  width: LINE_WIDTH
}, globalBg(color));
export function ballGrid() {
  let i = 1;
  const grid = {};

  for (; i < 10; i += 1) {
    const delay = parseInt((Math.random() * 100).toString(), 10) / 100 - 0.2;
    const duration = parseInt((Math.random() * 100).toString(), 10) / 100 + 0.6;
    grid[` > div:nth-of-type(${i})`] = {
      animationDelay: `${delay}s`,
      animationDuration: `${duration}s`
    };
  }

  return grid;
}