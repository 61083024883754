import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const ballScaleRipple = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale(0.1)'
  },
  '100%': {
    opacity: 0.0
  },
  '70%': {
    opacity: 0.7,
    transform: 'scale(1)'
  }
});
export default (color => ({
  ' > div': _objectSpread({}, globalAnimation(), {
    animation: `${ballScaleRipple} 1s 0s infinite cubic-bezier(.21,.53,.56,.8)`,
    border: `2px solid ${color || PRIMARY_COLOR}`,
    borderRadius: '100%',
    height: '50px',
    width: '50px'
  })
}));