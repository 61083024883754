import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation } from '../mixins';
import { PRIMARY_COLOR } from '../variables';
const squareSpin = keyframes({
  '100%': {
    transform: 'perspective(100px) rotateX(0) rotateY(0)'
  },
  '25%': {
    transform: 'perspective(100px) rotateX(180deg) rotateY(0)'
  },
  '50%': {
    transform: 'perspective(100px) rotateX(180deg) rotateY(180deg)'
  },
  '75%': {
    transform: 'perspective(100px) rotateX(0) rotateY(180deg)'
  }
});
export default (color => ({
  ' > div': _objectSpread({}, globalAnimation(), {
    animation: `${squareSpin} 3s 0s cubic-bezier(.09,.57,.49,.9) infinite`,
    background: color || PRIMARY_COLOR,
    height: '50px',
    width: '50px'
  })
}));