import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { globalAnimation, lines } from '../mixins';
import delay from '../utilities';
const radius = '20px';
const lineSpinFade = keyframes({
  '100%': {
    opacity: 1
  },
  '50%': {
    opacity: 0.3
  }
});

function lineSpinFadeLoader() {
  const n = 8;
  let i = 1;
  const grid = {};

  for (; i < 9; i += 1) {
    const quarter = parseInt(radius, 10) / 2 + parseInt(radius, 10) / 5.5;

    if (i === 1) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: 0,
        top: radius
      };
    } else if (i === 2) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: quarter,
        top: quarter,
        transform: 'rotate(-45deg)'
      };
    } else if (i === 3) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: radius,
        top: 0,
        transform: 'rotate(90deg)'
      };
    } else if (i === 4) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: quarter,
        top: `-${quarter}`,
        transform: 'rotate(45deg)'
      };
    } else if (i === 5) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: '0',
        top: `-${radius}`
      };
    } else if (i === 6) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: `-${quarter}`,
        top: `-${quarter}`,
        transform: 'rotate(-45deg)'
      };
    } else if (i === 7) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: `-${radius}`,
        top: '0',
        transform: 'rotate(90deg)'
      };
    } else if (i === 8) {
      grid[`> div:nth-of-type(${i})`] = {
        animation: `${lineSpinFade} 1.2s ${delay('0.12s', n, i)} infinite ease-in-out`,
        left: `-${quarter}`,
        top: quarter,
        transform: 'rotate(45deg)'
      };
    }
  }

  return grid;
}

export default (color => _objectSpread({}, lineSpinFadeLoader(), {
  left: '-4px',
  position: 'relative',
  top: '-10px',
  ' > div': _objectSpread({}, lines(color), {}, globalAnimation(), {
    height: '15px',
    position: 'absolute',
    width: '5px'
  })
}));