import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls } from '../mixins';
import delay from '../utilities';
import { PRIMARY_COLOR } from '../variables';
const size = '25px';
const rotatePacmanHalfUp = keyframes({
  '0%': {
    transform: 'rotate(270deg)'
  },
  '100%': {
    transform: 'rotate(270deg)'
  },
  '50%': {
    transform: 'rotate(360deg)'
  }
});
const rotatePacmanHalfDown = keyframes({
  '0%': {
    transform: 'rotate(90deg)'
  },
  '100%': {
    transform: 'rotate(90deg)'
  },
  '50%': {
    transform: 'rotate(0deg)'
  }
});
const pacmanBalls = keyframes({
  '100%': {
    transform: `translate(-${4 * parseInt(size, 10)}px, -${parseInt(size, 10) / 4}px)`
  },
  '75%': {
    opacity: 0.7
  }
});

const pacmanDesign = color => ({
  borderBottom: `${size} solid ${color || PRIMARY_COLOR}`,
  borderLeft: `${size} solid ${color || PRIMARY_COLOR}`,
  borderRadius: size,
  borderRight: `${size} solid transparent`,
  borderTop: `${size} solid ${color || PRIMARY_COLOR}`,
  height: '0px',
  width: '0px'
});

function ballPlacement() {
  const n = 3;
  let i = 0;
  const grid = {};

  for (; i < 4; i += 1) {
    grid[`> div:nth-of-type(${i + 2})`] = {
      animation: `${pacmanBalls} 1s ${delay('.33s', n, i)} infinite linear`
    };
  }

  return grid;
}

export default (color => _objectSpread({}, ballPlacement(), {
  position: 'relative',
  ' > div:first-of-type': _objectSpread({}, pacmanDesign(color), {
    animation: `${rotatePacmanHalfUp} 0.5s 0s infinite`,
    left: '-30px',
    position: 'relative'
  }),
  ' > div:nth-of-type(2)': _objectSpread({}, pacmanDesign(color), {
    animation: `${rotatePacmanHalfDown} 0.5s 0s infinite`,
    left: '-30px',
    marginTop: -2 * parseInt(size, 10),
    position: 'relative'
  }),
  ' > div:nth-of-type(3), > div:nth-of-type(4), > div:nth-of-type(5), > div:nth-of-type(6)': _objectSpread({}, balls(color), {
    height: '10px',
    left: '70px',
    position: 'absolute',
    top: '25px',
    transform: `translate(0,-${parseInt(size, 10) / 4}px)`,
    width: '10px'
  })
}));