import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { ballGrid, balls, globalAnimation } from '../mixins';
import { BALL_SIZE, MARGIN } from '../variables';
const ballGridPulse = keyframes({
  '0%': {
    transform: 'scale(1)'
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)'
  },
  '50%': {
    opacity: 0.7,
    transform: 'scale(0.5)'
  }
});
export default (color => _objectSpread({
  width: parseInt(BALL_SIZE, 10) * 3 + parseInt(MARGIN, 10) * 6,
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    animationDelay: '0',
    animationIterationCount: 'infinite',
    animationName: ballGridPulse,
    display: 'inline-block',
    float: 'left'
  })
}, ballGrid()));