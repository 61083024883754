import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { keyframes } from '@emotion/core';
import { balls, globalAnimation } from '../mixins';
const amount = '30px';
const ballZigDeflect = keyframes({
  '100%': {
    transform: 'translate(0, 0)'
  },
  '17%': {
    transform: `translate(-${parseInt(amount, 10) / 2}px, -${amount})`
  },
  '34%': {
    transform: `translate(${parseInt(amount, 10) / 2}px, -${amount})`
  },
  '50%': {
    transform: 'translate(0, 0)'
  },
  '67%': {
    transform: `translate(${parseInt(amount, 10) / 2}px, -${amount})`
  },
  '84%': {
    transform: `translate(-${parseInt(amount, 10) / 2}px, -${amount})`
  }
});
const ballZagDeflect = keyframes({
  '100%': {
    transform: 'translate(0, 0)'
  },
  '17%': {
    transform: `translate(${parseInt(amount, 10) / 2}px, ${amount})`
  },
  '34%': {
    transform: `translate(-${parseInt(amount, 10) / 2}px, ${amount})`
  },
  '50%': {
    transform: 'translate(0, 0)'
  },
  '67%': {
    transform: `translate(-${parseInt(amount, 10) / 2}px, ${amount})`
  },
  '84%': {
    transform: `translate(${parseInt(amount, 10) / 2}px, ${amount})`
  }
});
export default (color => ({
  position: 'relative',
  transform: `translate(-${parseInt(amount, 10) / 2}px, -${parseInt(amount, 10) / 2}px)`,
  ' > div': _objectSpread({}, balls(color), {}, globalAnimation(), {
    left: '-7px',
    marginLeft: parseInt(amount, 10) / 2,
    position: 'absolute',
    top: '4px',
    '&:first-of-type': {
      animation: `${ballZigDeflect} 1.5s 0s infinite linear`
    },
    '&:last-child': {
      animation: `${ballZagDeflect} 1.5s 0s infinite linear`
    }
  })
}));